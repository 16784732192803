import { Menu } from "../model/menu.model"

  export var BHungariaMenuItems: Menu[] = [
    {
      headTitle1: "3B hungária",
    },
    {
      title: "Dashboard",
      icon: "home",
      type: "link",
      path: "/3bh-controller/dashboard",
    },
    {
      title: "Párosítások",
      icon: "learning",
      type: "link",
      path: "/3bh-matchingitems/list", 
    },    
    {
      title: "Számlák",
      icon: "sample-page",
      type: "link",
      path: "/3bh-invoices/list",
    },   
    // {
    //     title: "menu.admin_center",
    //     icon: "social",
    //     //badgeType: "light-secondary",
    //     //badgeValue: "In process",
    //     type: "sub",
    //     active: false,
    //     children: [
    //     { path: "users", title: "menu.users", type: "link" },
    //     { path: "home/edit", title: "menu.appearance", type: "link" },
    //     { path: "audit/list", title: "menu.auditLogs", type: "link" },
    //     { path: "backup/list", title: "menu.backups", type: "link" },
    //     { path: "health", title: "menu.health", type: "link" },
    //     ],
    // },
    // {
    //   title: "menu.help",
    //   icon: "support-tickets",
    //   badgeType: "light-success",
    //   badgeValue: "New",
    //   type: "sub",
    //   active: false,
    //   children: [
    //     {
    //       title: 'Support',
    //       path: "/support",
    //       badgeType: "light-secondary",
    //       badgeValue: "In process",
    //       icon: 'support-tickets',
    //       type: 'link',
    //     },
    //     // {
    //     //   title: 'Impressum',
    //     //   path: "/impressum",
    //     //   badgeType: "light-secondary",
    //     //   badgeValue: "In process",
    //     //   icon: 'faq',
    //     //   type: 'link',
    //     // },
    //   ],
    // },
  ];