<div *ngIf="alertMessageColor === 'red'" class="col-12 dashboard-alert-red mb-2" role="alert">
    {{ alertMessage }}
</div>
<div *ngIf="alertMessageColor === 'blue'" class="col-12 dashboard-alert-blue mb-2" role="alert">
    {{ alertMessage }}
</div>
<div *ngIf="alertMessageColor === 'green'" class="col-12 dashboard-alert-green mb-2" role="alert">
    {{ alertMessage }}
</div>
<div *ngIf="alertMessageColor === 'yellow'" class="col-12 dashboard-alert-yellow mb-2" role="alert">
    {{ alertMessage }}
</div>
<div *ngIf="alertMessageColor === 'purple'" class="col-12 dashboard-alert-purple mb-2" role="alert">
    {{ alertMessage }}
</div>