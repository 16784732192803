import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent, Observable } from "rxjs";
import { takeUntil, debounceTime, filter } from "rxjs/operators";
import { Router } from "@angular/router";
import { MSPAdminMenuItems, MSPUserMenuItems } from "../menu/msp.menu";
import { KoronaMenuItems } from "../menu/korona.menu"
import { BarcodeMenuItems } from "../menu/barcode.menu"
import { EPICMenuItems } from "../menu/epic.menu";
import { FSMenuItems } from "../menu/fs.menu";
import { Menu } from "../model/menu.model";
import { NadudvariMenuItems, NadudvariTechMenuItems } from "../menu/nadudvari.menu";
import { FittingkerMenuItems } from "../menu/fittingker.menu";
import { HomeService } from "./home.service";
import { Home } from "../model/home.model";
import { ERPMenuItems } from "../menu/erp.menu";
import { CRMMenuItems } from "../menu/crm.menu";
import { MESMenuItems } from "../menu/mes.menu";
import { ETCMenuItems } from "../menu/etc.menu";
import { AllMenuItems } from "../menu/all.menu";
import { KnorrMenuItems } from "../menu/knorr.menu";
import { RoleService } from "./role.service";
import { SubRoleModel } from "../model/subRoleModel";
import { ShiftempoMenuItems } from "../menu/shiftempo.menu";
import { LabMenuItems } from "../menu/lab.menu";
import { DemoMenuItems } from "../menu/demo.menu";
import { BHungariaMenuItems } from "../menu/bhungaria.menu";

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {

  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);
  private home: Home;
  private role: SubRoleModel;

  private menuItemsMap = {
    "msp": MSPUserMenuItems,
    "epic": EPICMenuItems,
    "fsz": FSMenuItems,
    "nadudvari": NadudvariMenuItems,
    "demo": DemoMenuItems,
    "fittingker": FittingkerMenuItems,
    "korona": KoronaMenuItems,
    "barcode": BarcodeMenuItems,
    "erp": ERPMenuItems,
    "crm": CRMMenuItems,
    "mes": MESMenuItems,
    "etc": ETCMenuItems,
    "all": AllMenuItems,
    "lab": LabMenuItems,
    "knorr": KnorrMenuItems,
    "shiftempo": ShiftempoMenuItems,
    "bhungaria": BHungariaMenuItems
  };

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router,
    private homeService: HomeService,
    private roleService: RoleService) {

    this.getHome();
    //this.getRoleAuth();
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  // Array
  public items$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>([]);

  getItems(): Observable<Menu[]> {
    return this.items$.asObservable();
  }


  private updateItemsBasedOnHome(): void {
    this.items$.next(EPICMenuItems);
    if (this.home && this.home.menu == "msp" && this.role && this.role.menuId == "MSPUser") {
      this.items$.next(MSPUserMenuItems);
    }
    if (this.home && this.home.menu == "msp" && this.role && this.role.menuId == "MSPAdminMenu") {
      this.items$.next(MSPAdminMenuItems);
    }
    if (this.home && this.home.menu == "nadudvari" && this.role && this.role.menuId == "NadudvariTechnicalMenu") {
      this.items$.next(NadudvariTechMenuItems);
    }
    if (this.home && this.home.menu == "nadudvari" && this.role && this.role.menuId == "NadudvariAdminMenu") {
      this.items$.next(NadudvariMenuItems);
    }
    
    if (this.home && this.home.menu != "msp" && this.home.menu != "nadudvari" && this.home.menu in this.menuItemsMap) {
      this.items$.next(this.menuItemsMap[this.home.menu]);
    }
  }

  getHome() {
    this.homeService.getSelected()
      .pipe(
        filter(home => !!home)
      )
      .subscribe((r: Home) => {
        this.home = r;
        if (this.home.menu == "msp") {

          this.roleService.getMySubRole().subscribe((role) => {
            this.role = role;
            this.updateItemsBasedOnHome();
          });

        } else if (this.home.menu == "nadudvari") {

          this.roleService.getMySubRole().subscribe((role) => {
            this.role = role;
            this.updateItemsBasedOnHome();
          });

        } else {
          this.updateItemsBasedOnHome();
        }
      });
  }

  //Function Access
  // roleAuth: FunctionAccessModel;
  // roleAuthFunction = RoleFunction.MSPProductProperty;
  getRoleAuth() {
    this.roleService.getMySubRole().subscribe((role) => {
    });
  }
}