<div class="input-group mr-2">
    <input [ngClass]="ngControl?.valid ? 'ng-valid' : 'ng-invalid'" class="form-control" (blur)="inputBlur($event)" [ngModel]="dateString | date:inputDatetimeFormat" (change)="onInputChange($event)" [disabled]='disabled'/>
    <button class="btn custom-button" [ngbPopover]="calendarContent" [disabled]='disabled' type="button" (click)="showCalendar($event)">
        <i class="icofont icofont-ui-calendar"></i>
    </button>
    <button class="btn custom-button" [ngbPopover]="calendarContent" type="button" (click)="showTimePicker($event)">
        <i class="icofont icofont-clock-time"></i>
    </button>
</div>

<ng-template #calendarContent>
    <div >
        <div *ngIf="!showTimePickerToggle" >
            <ngb-datepicker #dp name="datepicker" [ngModel]="datetime"  (ngModelChange)="onDateChange($event)"></ngb-datepicker>
        </div>
        <div *ngIf="showTimePickerToggle" >
            <div class="mt-auto">
            <ngb-timepicker #tp name="timepicker" [ngModel]="datetime" (ngModelChange)="onTimeChange($event)" [seconds]="seconds"
            [hourStep]="hourStep" [minuteStep]="minuteStep" [secondStep]="secondStep"></ngb-timepicker>
            </div>
        </div>
    </div>
</ng-template>