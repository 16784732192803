import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss']
})
export class ProgressCircleComponent implements OnInit, AfterViewInit {
  @Input() percentage: number = 0; 
  @Input() color: string = '#7366ff'; 
  @ViewChild('circleContainer') circleContainer: ElementRef | undefined;

  radius: number = 0;
  circumference: number = 0;

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    if (this.circleContainer) {
      const size = this.circleContainer.nativeElement.offsetWidth;
      this.radius = size / 2;
      this.circumference = 2 * Math.PI * this.radius;
    }
  }

  // Calculate the length of the stroke based on the percentage
  getStrokeDasharray(): string {
    return `${(this.percentage / 100) * this.circumference} ${this.circumference}`;
  }

  // Set the stroke color dynamically
  getStrokeColor(): string {
    return this.color;
  }
}
