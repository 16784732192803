import { Menu } from "../model/menu.model"

  export var LabMenuItems: Menu[] = [
    {
      headTitle1: "Labor",
    },
    {
      title: "Kezdőlap",
      icon: "home",
      type: "link",
      path: "/dashboard/basic",
      badgeType: "light-secondary",
      badgeValue: "In process",
      active: true,
    },
    
    {
      title: "Gépek",
      icon: "social",
      badgeType: "light-secondary",
      badgeValue: "In process",
      type: "sub",
      active: false,
      children: [
        { path: "/resources/machines", title: "Gépek", type: "link" },
        { path: "/resources/machines/locations", title: "Helységek", type: "link" },
        { path: "/resources/machine-groups", title: "Csoportok", type: "link" },
      ],
    },

    {
      title: "Mérések",
      type: 'link',
      badgeType: "light-success",
      icon: "task",
      active: false,
      path: "/measurements/list",
    },
    { title: "Andon", type: "extLink", path: "http://10.7.65.240:82", active: false, icon: "project" },
    { title: "Andon üzenet", type: "link", path: "/nadudvari-message/edit", active: false, icon: "message" },
  {
    title: "menu.admin_center",
    icon: "social",
    //badgeType: "light-secondary",
    //badgeValue: "In process",
    type: "sub",
    active: false,
    children: [
      { path: "users", title: "menu.users", type: "link" },
      { path: "home/edit", title: "menu.appearance", type: "link" },
      { path: "audit/list", title: "menu.auditLogs", type: "link" },
      { path: "backup/list", title: "menu.backups", type: "link" },
      { path: "health", title: "menu.health", type: "link" },
    ],
  },
    {
      title: "menu.help",
      icon: "support-tickets",
      badgeType: "light-success",
      badgeValue: "New",
      type: "sub",
      active: false,
      children: [
        {
          title: 'Support',
          path: "/support",
          badgeType: "light-secondary",
          badgeValue: "In process",
          icon: 'support-tickets',
          type: 'link',
        },
        // {
        //   title: 'Impressum',
        //   path: "/impressum",
        //   badgeType: "light-secondary",
        //   badgeValue: "In process",
        //   icon: 'faq',
        //   type: 'link',
        // },
      ],
    },
  ];