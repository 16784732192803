import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environmentUrls } from '../../../environments/environment';
import { Home, HomeDetailDto } from '../model/home.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HomeService {
  homeSelected: Observable<Home>;
  homeEPIC: Observable<Home>;


  constructor(private http: HttpClient) { }

  getHomes() {
    return this.http.get<Home[]>(environmentUrls.home);
  }

  getSelected() {
    if (this.homeSelected == null) {
      this.homeSelected = this.http.get<Home>(environmentUrls.home + "/selected")
      return this.homeSelected;
    } else {
      return this.homeSelected;
    }
  }

  getEPIC() {
    if (this.homeEPIC == null) {
      this.homeEPIC = this.http.get<Home>(environmentUrls.home + "/epic");
      return this.homeEPIC;
    } else {
      return this.homeEPIC;
    }
  }

  setSelected(selectedHomeId: any) {
    return this.http.put(environmentUrls.home + "/" + selectedHomeId + "/select", "");
  }

  updateHome(home: HomeDetailDto){
    return this.http.put(environmentUrls.home + "/" + home.id, home);
  }

  getAlertMessage() {
    return this.http.get(environmentUrls.home + "/message");
  }
}