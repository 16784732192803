import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-dashboard-alert',
  templateUrl: './dashboard-alert.component.html',
  styleUrls: ['./dashboard-alert.component.scss']
})
export class DashboardAlertComponent implements OnInit {
  alertMessage: string;
  alertMessageColor: string;
  @ViewChild('alertContainer') alertContainer: ElementRef;

  constructor(
    private homeService: HomeService,
    private renderer: Renderer2
  ) {
    this.getAlertMessage();
  }

  ngOnInit() {
    this.getAlertMessage();
  }

  private getAlertMessage() {
    this.homeService.getAlertMessage().subscribe({
      next: (r: any) => {
        this.alertMessage = r.message;
        this.alertMessageColor = r.color;
      },
      error: (err) => {},
    });
  }
}
