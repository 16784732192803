<div class="progress-circle-container" #circleContainer>
  <!-- The background circle -->
  <svg width="100%" height="100%">
    <defs>
      <linearGradient id="gradientStroke" x1="0%" y1="100%" x2="30%" y2="0%">
        <stop offset="0%" [style]="'stop-color:'+getStrokeColor()+'; stop-opacity:0.6'" />
        <stop offset="100%" [style]="'stop-color:'+getStrokeColor()+'; stop-opacity:1'" />
      </linearGradient>
    </defs>

    <circle cx="50%" cy="50%" [attr.r]="radius" stroke="#e6e6e6" stroke-width="5" fill="none"></circle>
    <!-- The dynamic progress circle -->
    <!-- <circle cx="50%" cy="50%" [attr.r]="radius" [attr.stroke]="getStrokeColor()" stroke-width="10" fill="none"
            [attr.stroke-dasharray]="getStrokeDasharray()"></circle> -->
 
    <circle cx="50%" cy="50%"  [attr.r]="radius" stroke="url(#gradientStroke)" stroke-width="10" fill="none" [attr.stroke-dasharray]="getStrokeDasharray()"></circle> 
  </svg>

  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
