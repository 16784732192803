import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, forkJoin, map } from 'rxjs';

// model for a resource to load
export type Resource = { prefix: string; suffix: string };


export class MultiTranslateHttpLoader implements TranslateLoader {

  resources: Resource[] = [
    { prefix: './assets/i18n/', suffix: 'common.json' },
    { prefix: './assets/i18n/', suffix: 'company-group.json' },
    { prefix: './assets/i18n/', suffix: 'contact.json' },
    { prefix: './assets/i18n/', suffix: 'menu.json' },
    { prefix: './assets/i18n/', suffix: 'partner.json' },
    { prefix: './assets/i18n/', suffix: 'product.json' },
    { prefix: './assets/i18n/', suffix: 'support.json' },
    { prefix: './assets/i18n/', suffix: 'users.json' },
    { prefix: './assets/i18n/', suffix: 'roles.json' },
    { prefix: './assets/i18n/', suffix: 'machine.json' },
    { prefix: './assets/i18n/', suffix: 'nadudvar.json' },
    { prefix: './assets/i18n/', suffix: 'authentication.json' },
    { prefix: './assets/i18n/', suffix: 'audit.json' },
    { prefix: './assets/i18n/', suffix: 'modal.json' },
    { prefix: './assets/i18n/', suffix: 'backup.json' },
    { prefix: './assets/i18n/', suffix: 'health.json' },
    { prefix: './assets/i18n/', suffix: 'sales.json' },
    { prefix: './assets/i18n/', suffix: 'projects.json' },
  ];
  withCommon: boolean;

  constructor(
    private readonly http: HttpClient) {
  }

  getTranslation(lang: string): Observable<Record<string, unknown>> {
    let resources: Resource[] = [...this.resources];

    if (this.withCommon) {
      resources = [...resources];
    }

    return forkJoin(resources.map((config: Resource) => {
      return this.http.get<Record<string, unknown>>(`${config.prefix}${lang}/${config.suffix}`);
    })).pipe(
      map((response: Record<string, unknown>[]) =>
        mergeObjectsRecursively(response)),
    );
  }
}

export type MyRecord = Record<string, unknown>;

export const mergeObjectsRecursively = (objects: MyRecord[]): MyRecord => {
  const mergedObject: MyRecord = {};

  for (const obj of objects) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          if (typeof mergedObject[key] === 'object' && mergedObject[key] !== null) {
            // Cast to MyRecord when merging
            mergedObject[key] = mergeObjectsRecursively([mergedObject[key] as MyRecord, obj[key] as MyRecord]);
          } else {
            mergedObject[key] = mergeObjectsRecursively([obj[key] as MyRecord]);
          }
        } else {
          mergedObject[key] = obj[key];
        }
      }
    }
  }

  //console.log('mergedObject', mergedObject);
  return mergedObject;
};