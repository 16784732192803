import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceDecimal'
})
export class PriceDecimalPipe implements PipeTransform {

  transform(value: number | null, currency: string = 'Ft', ...args: unknown[]): string {
    if (value == null) {
      return '-';
    }

    // A szám formázása: 1.000,2 Ft vagy 1.000,2 Eur formátumra
    const formattedValue = value.toLocaleString('hu-HU', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
    
    return `${formattedValue} ${currency}`;
  }
}
